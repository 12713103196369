<template>
  <div>
    <div v-if="!isMySelf" class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <h3>{{ $t('members.member-detail.recommendations') }}</h3>
        <h5><b-icon-shield-check v-b-tooltip.hover.bottom size="2" :title="$t('members.member-detail.shield-tooltip')" class="mt-50 ml-25" /></h5>
      </div>
      <div class="d-flex align-items-center">
        <p v-if="membersRecommendations && total2" class="dark-text text-center m-0" :class="isStaff && 'mr-2 text-primary cursor-pointer'">
          {{ total2 }} {{ $t('members.member-detail.recommendations') }}
        </p>

        <b-button
          v-if="isStaff"
          id="connection-button"
          class="text-primary p-0 d-flex justify-content-end create-item"
          variant="link"
          @click="openConnectionModal"
        >
          <feather-icon icon="PlusIcon" variant="primary" size="20" />
        </b-button>
      </div>
    </div>
    <b-spinner
      v-if="isLoading"
      type="grow"
      small
      class="mx-auto d-block"
    />
    <b-row v-else-if="membersRecommendations.length > 0 && !isLoading">
      <b-col
        v-for="recommendedMember in membersRecommendations"
        :key="recommendedMember.key"
        cols="3"
        class="mt-1"
      >
        <b-link :to="getMemberLocation(recommendedMember)">
          <div class="d-flex justify-content-center">
            <user-avatar
              v-if="recommendedMember"
              :user="recommendedMember"
              size="46"
              class="mr-50 mb-25"
              style="background-color: lightgrey;"
            />
          </div>
          <div class="d-flex justify-content-center">
            <p v-if="recommendedMember" class="small m-0 text-center text-ellipsis-lines-2">
              {{ recommendedMember.name }} {{ recommendedMember.surname }}
            </p>
          </div>
        </b-link>
        <!--b-button 
          v-if="isMySelf" 
          variant="outline-primary" 
          class="d-block mx-auto p-25 p-lg-50 mt-lg-n1" 
          @click="handleConnect(recommendedMember)"
        >
          <small>{{ $t('members.connect') }}</small>
        </b-button-->
      </b-col>
      <div
        v-if="membersRecommendations && total2 && membersRecommendations.length < total2"
        class="w-100 mt-2 mb-2 d-flex justify-content-center"
      >
        <b-button variant="link" @click="handleLoadOfNewItems">
          {{ $t('members.show-more') }}
        </b-button>
      </div>
    </b-row>
    <!-- Container without data: Placeholder -->
    <div v-else class="horizontal-placeholder d-flex flex-column h-100 media-placeholder">
      <img :src="placeholderCollaborator">

      <p class="text-primary">
        {{ $t('available.message', { itemName: $t('members.member-detail.recommendations') }) }}
      </p>
    </div>
  </div>
</template>

<script>

import MembersPlaceholder from '@/assets/images/placeholders/light/members.svg';
import UserAvatar from '@/@core/components/user/UserAvatar.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import MemberAddConnectionsModal from '../modals/MemberAddConnectionsModal.vue';
//import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: 'MemberConnections',
  components: {
    UserAvatar,
    WidgetActions,
    MemberAddConnectionsModal,
  },
  props: {
    isEditing: Boolean,
    member: {
      type: Object,
      required: true,
    },
    isStaff: {
      type: Boolean,
      required: true,
    },
    isMySelf: {
      type: Boolean,
      required: true,
    },
    wantConnection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastLoadedPage: 1,
      isLoading: false,
    };
  },
  computed: {
    placeholderCollaborator() {
      return MembersPlaceholder;
    },
    // membersRecommendations() {
    //   return this.$store.getters.membersRecommendations;
    // },
    membersRecommendations() {
      if (this.$store.getters.communityMembers[this.$store.getters.currentCollective.slug]) {
        return this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ].unpaginated;
      }
      return [];
    },
    total2() {
      return this.$store.getters.membersRecommendations?.meta?.total;
    },
  },
  watch: {
    //
  },
  async created() {
    //await this.fetchDataMembers(this.member.key);
    await this.fetchDataMembers2(this.member.key);
    this.$emit('relations', this.membersRecommendations);
    this.$emit('total2', this.total2);
  },
  methods: {
    // async fetchDataMembers(memberKey, forceAPICall = true ) {
    //   this.isLoading = true;
    //   await this.$store.dispatch('getItems', {
    //     itemType: 'members',
    //     page: this.lastLoadedPage,
    //     forceAPICall,
    //     perPage: 16,
    //     requestConfig: { orderByDate: -1, memberKey },
    //   });
    //   this.isLoading = false;
    // },
    async fetchDataMembers2({ page, searchString, forceAPICall = false }) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "communityMembers",
        storedKey: this.$store.getters.currentCollective.slug,
        page,
        forceAPICall,
        perPage: 8,
        requestConfig: {
          //orderByName: OrderBy.ASC,
          searchString,
          //typeKey: this.finalTypeSelected,
          //organizationKey: null,
          //classifiers: this.finalSelectClassifier,
          // customFields:
          //   this.customFieldToSend != null &&
          //   Object.keys(this.customFieldToSend).length > 0
          //     ? this.customFieldToSend
          //     : null,
        },
      });
      this.isLoading = false;
    },
    async handleLoadOfNewItems() {
      if (this.membersRecommendations.length < this.total2) {
        this.lastLoadedPage += 1;
        await this.fetchDataMembers(this.lastLoadedPage, null, false);
      }
    },

    getMemberLocation(user) {
      if (!user.name) {
        return null;
      }
      return {
        name: 'member-details',
        params: { username: user.username, communityId: this.$route.params.communityId },
      };
    },
  },
};
</script>

<style></style>
